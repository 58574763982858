// Vendors
import { isWebViewPlatform } from '@openbank/web-ui-framework';
import { useLocation, useNavigate } from 'react-router-dom';
import { sessionUtils } from '@openbank/cf-ui-architecture-core';
import { useCallback, useEffect, useState } from 'react';
// Constants
import { NOTCH_ZERO } from './constants/mobile-connector.hook.constants';
// Enumerations
import { RoutesCommonEnumeration } from 'enumerations/routes.common.enumeration';
// Handlers
import MobileConnectorHandlers from '../handlers/mobile-connector.provider.handlers';
// Types
import { MobileConnectorHookReturnPropsType } from './types/mobile-connector-hook-return.type';
// Utilities
import {
  getAccessToken,
  getDeviceInfo,
  getUserName,
  setAccessToken,
  initMobileCommunicator,
  refreshToken,
  openWebViewBrowserLink,
} from 'utils/mobile-connector';
import { getEnvironmentDomain } from 'utils/app.utils';
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';

const MobileConnectorHook = (): MobileConnectorHookReturnPropsType => {
  const [notchSize, setNotchSize] = useState(NOTCH_ZERO);
  const [loading, setLoading] = useState<boolean>(true);
  const [hybridFlowId, setHybridFlowId] = useState<string | undefined>();

  const location = useLocation();
  const isInCreditLimitIncrease = location.pathname.includes(
    RoutesCommonEnumeration.CREDIT_LIMIT_INCREASE
  );
  const isHybrid = isWebViewPlatform();

  const {
    handleCancelHybridFlow: onCancelHybridFlow,
    handleRefreshToken: onRefreshToken,
    handleHybridFlow: onHybridFlow,
    handleSuccessHybridFlow: onSuccessHybridFlow,
    handleSendToMobileAccessToken,
    handleOpenExternalUrlOnHybridFlow,
  } = MobileConnectorHandlers({
    idFlow: hybridFlowId,
    isHybrid,
    navigate: useNavigate(),
    setHybridFlowId,
    setAccessToken,
  });

  const getBasicInfo = useCallback(async () => {
    const initialized = await initMobileCommunicator();
    if (initialized) {
      const accessToken = await getAccessToken();
      const refreshTokenValue = (await refreshToken()) || '';
      try {
        const mobileUsername: any = (await getUserName()) || '{username: {}}',
          username = JSON.parse(mobileUsername)?.username;
        localStorage.setItem(StorageCookiesEnum.USERNAME, username);
      } catch (e) {
        localStorage.setItem(StorageCookiesEnum.USERNAME, '');
      }

      if (accessToken) {
        // refreshTokenValue &&
        sessionUtils.saveSessionData({
          accessToken,
          refreshToken: refreshTokenValue,
          expiresIn: 100000,
          domain: getEnvironmentDomain(),
        });
      }
      getDeviceInfo().then((value: any) => {
        const deviceInfo = JSON.parse(value);
        const notchSize = deviceInfo.top_margin || deviceInfo.notch || NOTCH_ZERO;
        setNotchSize(notchSize);
      });
    } else {
      throw Error('initMobileCommunicator not initialized');
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isHybrid) {
        await getBasicInfo();
      }
      setLoading(false);
    };

    fetchData();
  }, [getBasicInfo, isHybrid]);

  return {
    fetching: loading && isHybrid,
    isHybrid,
    isInCreditLimitIncrease,
    notchSize,
    onCancelHybridFlow,
    onHybridFlow,
    onRefreshToken,
    onSuccessHybridFlow,
    handleOpenWebViewBrowser: openWebViewBrowserLink,
    handleOpenExternalUrlOnHybridFlow,
    handleSendToMobileAccessToken,
  };
};

export default MobileConnectorHook;
