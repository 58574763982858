/* eslint-disable complexity */
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import {
  TRANSACTION_CONFIGURATION_PROPS,
  TRANSACTIONS_TABLE_DEFAULT_ERROR_CONFIGURATION,
  TRANSACTIONS_TABLE_ERROR_CONFIGURATIONS,
} from './constants/transactions-table.configuration.constants';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Translations
import translations from './translations/transactions-table.translations';
// Types
import { AppReturnConfigurationType } from 'configurations/types/app-return.configuration.type';
import { TransactionsTableConfigurationType } from './types/transactions-table.configuration.type';
// Utils
import { getTableOrganismPropsById } from 'organisms/table-organism/utils/table-organism.utils';

const getTransactionsTableErrorConfiguration = ({
  formatMessage,
  error,
  handleNavigateToTransactionsViewButtonClick,
  idTable,
}: TransactionsTableConfigurationType): AppReturnConfigurationType | Record<string, any> => {
  const errorConfiguration = {
    ...TRANSACTIONS_TABLE_DEFAULT_ERROR_CONFIGURATION,
    ...TRANSACTIONS_TABLE_ERROR_CONFIGURATIONS[error || ''],
    formatMessage,
    translations: translations[error || ''],
  };
  switch (error) {
    case TransactionsFetchErrorEnumeration.NO_RESULTS:
    case TransactionsFetchErrorEnumeration.FETCH:
      if (getTableOrganismPropsById(idTable).isFinancing) {
        errorConfiguration.buttonConfiguration = {
          children: formatMessage({ id: translations.GO_TO_TRANSACTIONS }),
          onClick: handleNavigateToTransactionsViewButtonClick,
        };
      }
      return getFetchErrorConfiguration(errorConfiguration);

    default:
      return {};
  }
};

export { getTransactionsTableErrorConfiguration };
