// Handlers
import { fetchCardTransactionsHandler } from './fetch-card-transactions.handler';
// Types
import { TransactionsTableBuilderReturnHandlersType } from './types/transactions-table-builder-return.handlers.type';
import { TransactionsTableBuilderHandlersType } from './types/transactions-table-builder.handlers.type';

const setOutProvidingErrorEvent = ({
  setError,
  wrapperError,
}: Pick<TransactionsTableBuilderHandlersType, 'setError' | 'wrapperError'>): void => {
  setError(wrapperError);
};

const showSecurityCheckModalHandler = ({
  setShowSecurityCheckModal,
}: Pick<TransactionsTableBuilderHandlersType, 'setShowSecurityCheckModal'>): void => {
  setShowSecurityCheckModal?.(true);
};

const resetTransactionsHandler = (props: TransactionsTableBuilderHandlersType): void => {
  props.setElements([]);
  fetchCardTransactionsHandler({ ...props, filter: true });
};

export const TransactionsTableHandlers = (
  props: TransactionsTableBuilderHandlersType
): TransactionsTableBuilderReturnHandlersType => ({
  handleFetchCardTransactions: () => {
    fetchCardTransactionsHandler(props);
  },
  handleResetTransactions: () => {
    resetTransactionsHandler(props);
  },
  handleShowSecurityCheckModal: () => {
    showSecurityCheckModalHandler({ setShowSecurityCheckModal: props.setShowSecurityCheckModal });
  },
  handleSetOutProvidingErrorEvent: () => {
    setOutProvidingErrorEvent({ setError: props.setError, wrapperError: props.wrapperError });
  },
});
