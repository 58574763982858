// Services
import {
  fetchCardArtsService,
  fetchCardCvvService,
  fetchCardDetailsService,
  fetchCardPinService,
  fetchCardPanService,
  fetchCardsResourcesService,
} from '../services/cards.services';
// Types
import {
  CheckCvvServiceResponse,
  CheckPinServiceResponse,
} from 'containers/card-settings/views/check-cvv-pin/types/check-cvv-pin.type';
import { CardComponentType } from 'types/card.component.type';
import { CardTransactionType } from 'types/card-transaction.type';
import { CardArtComponentType } from 'types/card-art.component.type';
// Utilities
import { hasCards, isForbiddenError, isValidCardsResourcesContract } from './utils/card.resolvers.utils';

const fetchCardDetailsResolver = async (
  cardId: string
): Promise<[CardTransactionType] | [null, true]> => {
  const [response] = await fetchCardDetailsService(cardId);

  return response?.resource ? [response?.resource] : [null, true];
};

const fetchCardsResourcesResolver = async (): Promise<
  [CardComponentType[]] | [null, boolean | number]
> => {
  const [response, { status }] = await fetchCardsResourcesService();
  if (hasCards(response, status)) {
    return [null, status];
  }
  return isValidCardsResourcesContract(response) ? [response.elements] : [null, true];
};

const fetchCardPanResolver = async (cardId: string): Promise<[string] | [null, true]> => {
  const [response] = await fetchCardPanService(cardId);

  return response?.resource?.pan ? [response?.resource?.pan] : [null, true];
};

const fetchCardArtsResolver = async (
  productType: string
): Promise<[Array<CardArtComponentType>] | [null, true]> => {
  const [response] = await fetchCardArtsService(productType);

  return response?.cardArts ? [response?.cardArts] : [null, true];
};

const fetchCardCvvResolver = async (
  cardId: string,
  progressId?: string
): Promise<[CheckCvvServiceResponse['resource']] | [null, true | number]> => {
  const [response] = await fetchCardCvvService({ cardId, progressId });

  if (isForbiddenError(response)) {
    return [null, 403];
  }

  return response?.resource ? [response.resource] : [null, true];
};

const fetchCardPinResolver = async (
  cardId: string,
  progressId?: string
): Promise<[CheckPinServiceResponse['resource']] | [null, true | number]> => {
  const [response] = await fetchCardPinService({ cardId, progressId });

  if (isForbiddenError(response)) {
    return [null, 403];
  }

  return response?.resource ? [response.resource] : [null, true];
};

export {
  fetchCardArtsResolver,
  fetchCardDetailsResolver,
  fetchCardCvvResolver,
  fetchCardPanResolver,
  fetchCardPinResolver,
  fetchCardsResourcesResolver,
};
