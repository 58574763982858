// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Types
import { CardComponentType } from 'types/card.component.type';

const isValidCurrentCard = (currentCard: CardComponentType | Record<string, unknown>): boolean =>
  Boolean(currentCard?.cardContractId && currentCard?.cmsCardId);

const cardIsInactive = (status: string): boolean => status === CardStatusTypesEnumeration.INACTIVE;

const cardActive = (status: string): boolean => status === CardStatusTypesEnumeration.ACTIVE;

export { cardActive, cardIsInactive, isValidCurrentCard };
