// Vendors
import styled, { css } from 'styled-components';
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';
// Utils
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';

export const AppCardSectionComponentStyled = styled.div`
  background: ${({ theme }) => theme.colors.backgroundPrimaryB};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  box-shadow: 0 ${convertPxsToRems({ pixels: 3, base: 14 })}
    ${convertPxsToRems({ pixels: 3, base: 14 })} 0
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  width: 100%;
`;

export const AppCardStatusSectionComponentStyled = styled.div`
  margin: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingXxxxxl}`};

  ${mobileMediaQuery} {
    margin: ${({ theme }) => `${theme.spaces.spacingXs} ${theme.spaces.spacingS}`};
  }

  ${tabletMediaQuery} {
    margin: ${({ theme }) =>
      `${theme.spaces.spacingS} ${theme.spaces.spacingXl} ${theme.spaces.spacingS} ${theme.spaces.spacingXl}`};
  }
`;

export const AppCardContentSectionComponentStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingL}
    calc(${({ theme }) => theme.spaces.spacingXxxxl} + ${({ theme }) => theme.spaces.spacingS});

  ${tabletMediaQuery} {
    padding: calc(
      ${({ theme }) => theme.spaces.spacingL} + ${({ theme }) => theme.spaces.spacingXs}
    );
    ${({ theme }) => theme.spaces.spacingL};
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} 0;
  }
`;

export const AppCardHorizontalSectionComponentStyled = styled(AppCardContentSectionComponentStyled)`
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;

  ${mobileAndTabletMediaQuery} {
    padding: 0;
  }
`;

export const AppCardVerticalSectionComponentStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingL} 0;
  width: 100%;

  ${tabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS} 0;
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS} 0 0;
  }
`;

export const AppCardHeaderSectionComponentStyled = styled(AppCardContentSectionComponentStyled)`
  border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding-top: 0;
  flex-direction: column;
  width: 100%;

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingL};
  }
`;

export const LayoutGridComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxl};

  ${tabletMediaQuery} {
    gap: ${({ theme }) => (isSecondLevelRoutePath() ? 0 : theme.spaces.spacingL)};
  }

  ${mobileMediaQuery} {
    gap: ${({ theme }) => (isSecondLevelRoutePath() ? 0 : theme.spaces.spacingS)};
  }
`;

export const LayoutGridContentComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileAndTabletMediaQuery} {
    flex-direction: column;
  }
`;

export const LayoutGridContentLeftComponentStyled = styled.div`
  flex: 1;
  position: relative;

  ${mobileAndTabletMediaQuery} {
    width: 100%;
  }
`;

export const LayoutGridContentRightComponentStyled = styled(LayoutGridComponentStyled)<{
  maxWidth?: number;
}>`
  flex: 0 0 auto;
  gap: ${({ theme }) => theme.spaces.spacingL};
  ${({ maxWidth = 276 }) =>
    maxWidth && `max-width: ${convertPxsToRems({ pixels: maxWidth, base: 14 })}`};

  ${mobileAndTabletMediaQuery} {
    flex: 100%;
    max-width: 100%;
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
    width: 100%;
  }
`;

export const ParagraphSecondaryComponentStyled = styled(ParagraphComponent)`
  color: ${({ theme }) => theme.colors.linkSecondaryB};
`;

export const SidebarComponentStyled = styled.aside`
  max-width: ${convertPxsToRems({ pixels: 330, base: 14 })};

  ${mobileAndTabletMediaQuery} {
    max-width: 100%;
  }

  ${mobileMediaQuery} {
    margin-bottom: ${({ theme }) => theme.spaces.spacingM};
  }
`;

const LayoutLoaderStyled = styled.div<{ fetching: boolean }>`
  ${({ fetching }) => fetching && { height: '100vh' }};
`;

const LayoutErrorCardStyled = styled.div<{ error: boolean }>`
  ${({ error }) =>
    error &&
    css`
      padding: ${({ theme }) => theme.spaces.spacingXxxl};

      ${mobileMediaQuery} {
        padding: ${({ theme }) => theme.spaces.spacingXxxl} ${({ theme }) => theme.spaces.spacingM};
      }
    `};
`;

const LayoutColumnFlexComponentStyled = styled.div<{ align?: string; gap?: string }>`
  align-items: ${({ align = 'flex-start' }) => align};
  display: flex;
  flex-direction: column;
  gap: ${({ gap, theme }) => gap || theme.spaces.spacingM};
  width: 100%;
`;

const LayoutRowFlexComponentStyled = styled.div<{ gap?: string }>`
  align-items: flex-start;
  display: flex;
  gap: ${({ gap, theme }) => gap || theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;
LayoutRowFlexComponentStyled.displayName = 'LayoutRowFlexComponentStyled';

const LayoutMain1200ComponentStyled = styled.div`
  max-width: ${({ theme }) => convertPxsToRems({ pixels: 1200, base: 14 })};
  margin: ${({ theme }) => theme.spaces.spacingM} auto 0;
  width: 100%;
`;
LayoutMain1200ComponentStyled.displayName = 'LayoutMain1200ComponentStyled';

const LayoutPaddingRootHeaderFooterStyled = styled.div<{ isSecondLevel: boolean }>`
  padding: ${({ isSecondLevel, theme }) => (isSecondLevel ? 0 : theme.spaces.spacingL)} 0
    ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    padding: 0;
  }
`;
LayoutPaddingRootHeaderFooterStyled.displayName = 'LayoutPaddingRootHeaderFooterStyled';

const Flex1ComponentStyled = styled.div`
  flex: 1;

  ${mobileMediaQuery} {
    width: 100%;
    flex: 100%;
  }
`;
Flex1ComponentStyled.displayName = 'Flex1ComponentStyled';

const FlexAutoComponentStyled = styled.div`
  flex: 0 0 auto;
`;
FlexAutoComponentStyled.displayName = 'FlexAutoComponentStyled';

const LayoutHorizontalPaddingStyledComponent = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.spacingXxxxxl};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingXl};
  }

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
LayoutHorizontalPaddingStyledComponent.displayName = 'LayoutHorizontalPaddingStyledComponent';

const RowStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export {
  RowStyled,
  LayoutErrorCardStyled,
  LayoutLoaderStyled,
  LayoutColumnFlexComponentStyled,
  LayoutRowFlexComponentStyled,
  LayoutMain1200ComponentStyled,
  LayoutPaddingRootHeaderFooterStyled,
  LayoutHorizontalPaddingStyledComponent,
  Flex1ComponentStyled,
  FlexAutoComponentStyled,
};
