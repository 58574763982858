// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/notifications.services.constants';
// Types
import { NotificationsListServiceProps } from './types/notifications.services.type';

const fetchNotificationsList = async ({
  params = {},
  filters,
}: NotificationsListServiceProps): Promise<[Record<string, any>, Response]> => {
  const defaultDateFrom = new Date(constants.INITIAL_DATE).toISOString();
  const defaultDateDateTo = new Date().toISOString();

  return apiUtilsCb.get({
    ...constants.NOTIFICATIONS_SERVICE_DETAILS,
    queryParams: {
      dateFrom: filters?.fromDate ? new Date(filters?.fromDate).toISOString() : defaultDateFrom,
      dateTo: filters?.toDate ? new Date(filters?.toDate).toISOString() : defaultDateDateTo,
      page: params.page || constants.DEFAULT_PARAMS.PAGE,
      size: params.size || constants.DEFAULT_PARAMS.SIZE,
      types: constants.TYPE,
      family: constants.FAMILY,
    },
  });
};

const fetchNotificationsUpdate = async (): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.put({
    ...constants.NOTIFICATIONS_SERVICE_MODIFICATION,
    queryParams: {
      types: constants.TYPE,
      family: constants.FAMILY,
    },
  });

export { fetchNotificationsList, fetchNotificationsUpdate };
