// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Types
import { AddressType } from 'types/address.type';
import { AddressesUtilsType } from 'utils/addresses/types/addresses.utils.type';
import { CharactersEnum } from '@openbank/cf-ui-static-data';
import {
  ADDRESS_ATTRIBUTES_FOR_FILTER,
  ADDRESS_ATTRIBUTES_FOR_TRANSLATE,
} from './constants/addresses.utils.constants';
import { GetSelectedAddressType } from './types/get-selected-address.type';
// Translations
import { LABELS } from './translations/addresses.translations';

const createProxiedAndOrderedStreet = ({
  address,
  formatMessage,
}: AddressesUtilsType): Record<string, string> =>
  Object.fromEntries(
    Object.entries(address)
      .filter(item => ADDRESS_ATTRIBUTES_FOR_FILTER.includes(item[0]))
      .map(item =>
        ADDRESS_ATTRIBUTES_FOR_TRANSLATE.includes(item[0])
          ? [item[0], formatMessage({ id: `${LABELS[item[0]]}${item[1]}` })]
          : item
      )
      .filter(item => item[1])
      .sort(
        (a, b) =>
          ADDRESS_ATTRIBUTES_FOR_FILTER.findIndex(e => e === a[0]) -
          ADDRESS_ATTRIBUTES_FOR_FILTER.findIndex(e => e === b[0])
      )
  );

const prettyRawCustomerAddress = (props: AddressesUtilsType): string =>
  Object.values(createProxiedAndOrderedStreet(props)).join(CharactersEnum.COMMA_SPACE);

const isTemporalAddress = (address: AddressTypesEnum): boolean =>
  address === AddressTypesEnum.TEMP && !!address;

const getSelectedAddress = ({
  selectedAddress,
  tempAddress,
  customerAddress,
}: GetSelectedAddressType): AddressType =>
  isTemporalAddress(selectedAddress) && tempAddress ? tempAddress : customerAddress;

export { getSelectedAddress, isTemporalAddress, prettyRawCustomerAddress };
