// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Event handlers
import { TransactionsTableHandlers } from '../handlers/transactions-table.handlers';
// Hooks
import useFinancingErrorButtonHook from './financing-error-button.hook';
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
// Types
import { TransactionsTableBuilderHookType } from 'organisms/table-organism/components/transactions-table/hooks/types/transactions-table-builder.hook.type';
import { TransactionsTableHookType } from 'organisms/table-organism/components/transactions-table/hooks/types/transactions-table.hook.type';
import { CardTransactionType } from 'types/card-transaction.type';
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';
// Utils
import {
  hasTransactionsChallengeSuccess,
  isNotDashboardRoute,
} from '../utils/transactions-table.utils';
import { ChallengeType } from 'types/challenge.type';

export const TransactionsTableHook = ({
  error: wrapperError = null,
  resolver,
  transactions,
  idName,
}: TransactionsTableBuilderHookType): TransactionsTableHookType => {
  const { elements: tElements = [], metadata: tMetadata = {} } = transactions || {};
  const { setError: setErrorManager } = ManagerContextConsumerHook();
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<TransactionsFetchErrorEnumeration | null>(wrapperError);
  const [elements, setElements] = useState<CardTransactionType[] | []>(tElements as any);
  const [metadata, setMetadata] = useState<
    TransactionsTableMetadataComponentType | Record<string, any>
  >(tMetadata);

  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [showSecurityCheckModal, setShowSecurityCheckModal] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId = '' } = currentCard || {};

  const { handleNavigateToTransactionsViewButtonClick } = useFinancingErrorButtonHook();
  const { formValuesParams, formValues, setFormValuesParams, setFormValues, setCopyOfFormValues } =
    useFiltersContextConsumerHook();

  const {
    handleFetchCardTransactions,
    handleSetOutProvidingErrorEvent,
    handleShowSecurityCheckModal,
    handleResetTransactions,
  } = TransactionsTableHandlers({
    cardId,
    challenge,
    formValuesParams,
    metadata,
    resolver,
    setChallenge,
    setChallengeError,
    setElements,
    setError,
    setErrorManager,
    setFetching,
    setMetadata,
    setShowSecurityCheckModal,
    wrapperError,
    idName,
  });

  useEffect(() => {
    resolver && handleResetTransactions();
    if (location.state?.fetchTransactions) {
      setShowSecurityCheckModal(true);
      navigate(location.pathname, { replace: true });
    }
  }, [formValuesParams]);

  useEffect(() => {
    handleSetOutProvidingErrorEvent();
  }, [wrapperError]);

  useEffect(() => {
    if (isNotDashboardRoute() && !showSecurityCheckModal && hasTransactionsChallengeSuccess()) {
      handleResetTransactions();
    }
  }, [showSecurityCheckModal, hasTransactionsChallengeSuccess]);

  return {
    challenge,
    challengeError,
    elements,
    error,
    fetching,
    formatMessage,
    formValues,
    formValuesParams,
    handleFetchCardTransactions,
    handleNavigateToTransactionsViewButtonClick,
    handleShowSecurityCheckModal,
    metadata,
    setChallenge,
    setChallengeError,
    setError,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setShowSecurityCheckModal,
    showSecurityCheckModal,
  };
};
