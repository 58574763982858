// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
import { TransactionsTableHeaderLinkBarListLinksComponentCC } from './components/list-links/transactions-table-header-link-bar-list-links.component.cc';
// Constants
import constants from './constants/transactions-table-header-link-bar.constants';
// Hooks
import TransactionsTableHeaderLinkBarHook from './hooks/transactions-table-header-link-bar.hook';
// Styles
import { TransactionsTableHeaderLinkBarComponentStyled } from './transactions-table-header-link-bar.component.styled';
// Translations
import translations from './translations/transactions-table-header-link-bar.translations';
// Types
import { TransactionsTableHeaderLinkBarComponentPropsType } from './types/transactions-table-header-link-bar.component.type';
// Utils
import { isCC } from 'utils/app.utils';

const TransactionsTableHeaderLinkBarComponent = ({
  showActionBar,
  listLinksConfigurations,
}: TransactionsTableHeaderLinkBarComponentPropsType): React.ReactElement | null => {
  const { handleDownloadTransactionsLinkClick, handleFilterTransactionsLinkClick } =
    TransactionsTableHeaderLinkBarHook();

  return showActionBar ? (
    <TransactionsTableHeaderLinkBarComponentStyled>
      <TransactionsTableHeaderLinkBarListLinksComponentCC {...{ listLinksConfigurations }} />
      <LinkComponent
        {...constants.TRANSACTIONS_TABLE_LINK_FILTER_PROPS}
        onClick={handleFilterTransactionsLinkClick}
      >
        <FormattedMessageComponent id={translations.FILTERS} />
      </LinkComponent>
      {!isCC() && (
        <LinkComponent
          {...constants.TRANSACTIONS_TABLE_LINK_VIEW_OR_DOWNLOAD_PROPS}
          onClick={handleDownloadTransactionsLinkClick}
        >
          <FormattedMessageComponent id={translations.VIEW_OR_DOWNLOAD} />
        </LinkComponent>
      )}
    </TransactionsTableHeaderLinkBarComponentStyled>
  ) : null;
};

export default TransactionsTableHeaderLinkBarComponent;
