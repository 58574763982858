// Constants
import {
  ASCENDING_DESCENDING_REGEX,
  CONSECUTIVE_NUMBERS_REGEX,
  EQUALS_4_NUMBERS,
  ONLY_4_NUMBERS,
} from './constants/access-key-constraints.constants.utils';

const checkHasFourNumbersOnly = (value: string): boolean => Boolean(value.match(ONLY_4_NUMBERS));

const checkNoAscendingDescendingSequences = (value: string): boolean =>
  Boolean(value.match(ASCENDING_DESCENDING_REGEX));

const checkNoUse4EqualsNumbers = (value: string): boolean => !value.match(EQUALS_4_NUMBERS);

const noContain3OrMoreConsecutiveAndEqualsNumbers = (value: string): boolean =>
  value.match(CONSECUTIVE_NUMBERS_REGEX) !== null;

export {
  checkNoAscendingDescendingSequences,
  checkHasFourNumbersOnly,
  checkNoUse4EqualsNumbers,
  noContain3OrMoreConsecutiveAndEqualsNumbers,
};
