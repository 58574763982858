import { TRANSACTION_CONFIGURATION_PROPS } from '../components/transactions-table/configurations/constants/transactions-table.configuration.constants';
import { TransactionsTableOrganismPropsReturnType } from '../components/transactions-table/types/transactions-table-configurations.component.type';
import {
  CARD_STATEMENTS_ORGANISM_TABLE_PROPS,
  DASHBOARD_ORGANISM_TABLE_PROPS,
  FINANCING_ORGANISM_TABLE_PROPS,
  TRANSACTION_ORGANISM_TABLE_PROPS,
} from '../constants/table-organism.constants';

const getTableOrganismPropsById = (
  idTable: string | undefined
): TransactionsTableOrganismPropsReturnType => {
  switch (idTable) {
    case 'dashboard':
      return DASHBOARD_ORGANISM_TABLE_PROPS;
    case 'financing':
      return FINANCING_ORGANISM_TABLE_PROPS;
    case 'transactions':
      return TRANSACTION_ORGANISM_TABLE_PROPS;
    case 'card-statements':
      return CARD_STATEMENTS_ORGANISM_TABLE_PROPS;
    default:
      return TRANSACTION_CONFIGURATION_PROPS;
  }
};

export { getTableOrganismPropsById };
