// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbError.';

export default {
  [TransactionsFetchErrorEnumeration.FETCH]: {
    ERROR_DESCRIPTION: `${DOMAIN}apiErrorDescription`,
    ERROR_TITLE: `${DOMAIN}apiErrorTitle`,
  },
  [TransactionsFetchErrorEnumeration.NO_RESULTS]: {
    ERROR_TITLE: `${DOMAIN}resultsErrorTitle`,
    ERROR_DESCRIPTION: `${DOMAIN}resultsErrorDescription`,
  },
  GO_TO_TRANSACTIONS: `${DOMAIN}goToTransactions`,
};
