// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Utils
import { requestRespondedNoContent, wasForbiddenRequest } from '@openbank/cf-ui-framework';

const getErrorAccordingStatus = (status: number): TransactionsFetchErrorEnumeration => {
  switch (true) {
    case requestRespondedNoContent(status):
      return TransactionsFetchErrorEnumeration.NO_RESULTS;
    case wasForbiddenRequest(status):
      return TransactionsFetchErrorEnumeration.CHALLENGE;
    default:
      return TransactionsFetchErrorEnumeration.FETCH;
  }
};

export { getErrorAccordingStatus };
