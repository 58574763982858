export default {
  NOTIFICATIONS_SERVICE_DETAILS: {
    endpoint: 'mb-api-cm-notifications/v2/messages',
  },
  NOTIFICATIONS_SERVICE_MODIFICATION: {
    endpoint: 'mb-api-cm-notifications/v1/messages/status/all',
    queryParams: { types: 'WEB'}
  },
  TYPE: 'WEB',
  FAMILY: 'COBRANDED',
  INITIAL_DATE: '2024-01-01',
  DEFAULT_PARAMS: { PAGE: 0, SIZE: 10 },
};
