const FETCH_POE_CONFIGURATION = {
    endpoint: 'cb-api-localization/v1/public/platform/WEB/configuration',
  },
  TRANSLATIONS_SERVICE_CUSTOM_HEADERS = {
    customHeaders: {
      bankId: 'MB_DEU',
    },
  },
  FETCH_TRANSLATIONS_SERVICE_CONFIGURATION = {
    endpoint: 'mb-api-translations/v2/internationalization/:platform/translation/latest',
    ...TRANSLATIONS_SERVICE_CUSTOM_HEADERS,
  };

export {
  FETCH_POE_CONFIGURATION,
  FETCH_TRANSLATIONS_SERVICE_CONFIGURATION,
  TRANSLATIONS_SERVICE_CUSTOM_HEADERS,
};
