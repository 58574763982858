// Vendors
import { useErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useQuickActionsContextConsumerHook } from 'contexts/quick-actions/quick-actions.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Event handlers
import CustomerHandlers from '../handlers/cards.handlers';
// Types
import { CardsHookType } from './types/cards.hook.types';
import LogoutHandlers from 'handlers/logout.handlers';

const CardsHook = (): CardsHookType => {
  const { showBoundary: setError } = useErrorBoundary();
  const { formatMessage } = useCbIntl();
  const { setCurrentCard, setCards } = AppContextConsumerHook(),
    { card, setCard } = ManagerContextConsumerHook(),
    { fetching, setFetching } = ManagerContextConsumerHook(),
    { setActions, setQuickActions } = useQuickActionsContextConsumerHook();

  const [errorCard, setErrorCard] = useState<boolean>(false);
  const { handleMakeUserLogoutEvent } = LogoutHandlers();

  const { handleSetupCardInformationEvent } = CustomerHandlers({
    setCurrentCard,
    setCard,
    setCards,
    setFetching,
    setError,
    setErrorCard,
    setActions,
    setQuickActions,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupCardInformationEvent();
  }, []);

  useEffect(() => {
    card && handleSetupCardInformationEvent();
  }, [card]);

  return {
    errorCard,
    fetching,
    formatMessage,
    handleSetupCardInformationEvent,
    handleMakeUserLogoutEvent,
  };
};

export default CardsHook;
