// Types
import {
  MobileConnectorHandlersBuilderType,
  MobileConnectorSuccessHybridFlowType,
  MobileConnectorCancelHybridFlowType,
  MobileConnectorHandlersReturnType,
  MobileConnectorOpenExternalUrlOnHybridFlowPropsType,
} from './types/mobile-connector.provider.handlers.type';
// Utils
import { finishProcess, refreshToken, openBrowserLink } from 'utils/mobile-connector';
import { clearSessionData } from 'utils/session/session.utils';
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
import { cookieStorage } from '@gruposantander/web-ui-framework';

const cancelHybridFlow = ({
  isHybrid,
  navigate,
  url,
  idFlow: id_flow,
}: MobileConnectorCancelHybridFlowType): void => {
  if (!isHybrid) {
    url ? navigate(url) : navigate(-1);
  } else if (isHybrid && id_flow) {
    finishProcess({ id_flow });
    clearSessionData();
  }
};

const refreshTokenMobile = (): Promise<void> | void => {
  refreshToken();
};

const successHybridFlow = ({
  isHybrid,
  navigate,
  url,
  idFlow: id_flow,
}: MobileConnectorSuccessHybridFlowType): void => {
  cancelHybridFlow({ isHybrid, navigate, url, idFlow: id_flow });
};

const sendToMobileAccessToken = (
  setAccessToken: (data: { accessToken: string }) => Promise<void>
): void => {
  const accessToken = cookieStorage.getItem(StorageCookiesEnum.ACCESS_TOKEN);

  accessToken && setAccessToken?.({ accessToken });
};

const openExternalUrlOnHybridFlowHandler = ({
  isHybrid,
  url,
}: MobileConnectorOpenExternalUrlOnHybridFlowPropsType) => {
  isHybrid ? openBrowserLink(url) : setTimeout(() => window.open(url, '_blank'));
};

const MobileConnectorHandlers = ({
  idFlow,
  isHybrid,
  navigate,
  setHybridFlowId,
  setAccessToken,
}: MobileConnectorHandlersBuilderType): MobileConnectorHandlersReturnType => ({
  handleCancelHybridFlow: (url?: string) => cancelHybridFlow({ isHybrid, navigate, url, idFlow }),
  handleHybridFlow: (path: string) => setHybridFlowId(path),
  handleRefreshToken: () => refreshTokenMobile(),
  handleSuccessHybridFlow: ({ navigate, url }) =>
    successHybridFlow({ isHybrid, navigate, url, idFlow }),
  handleSendToMobileAccessToken: () => sendToMobileAccessToken(setAccessToken),
  handleOpenExternalUrlOnHybridFlow: (url: string) =>
    openExternalUrlOnHybridFlowHandler({ isHybrid, url }),
});

export default MobileConnectorHandlers;
