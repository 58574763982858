const DOMAIN = 'cobranded.services.userSettings.privateArea.commons.';

export default {
  WORK_IN_PROGRESS: {
    TITLE: `${DOMAIN}workInProgress.title`,
    DESCRIPTION: `${DOMAIN}workInProgress.description`,
  },
  WIZARD: {
    TEXT_SEPARATOR: `${DOMAIN}wizard.textSeparator`,
  },
};
