const DOMAIN = 'cobranded.services.userSettings.privateArea.cbChallengeModule.smsChallenge.';

const BUTTON = `${DOMAIN}sendButton`,
  DESCRIPTION = `${DOMAIN}description`,
  ERROR = `${DOMAIN}cbInput.errorDescription`,
  HELPER = `${DOMAIN}requestNewCodeDescription`,
  REQUEST_LINK = `${DOMAIN}requestNewCodeLink`,
  PLACEHOLDER = `${DOMAIN}inputOtpPlaceholder`,
  TITLE = `${DOMAIN}title`;

export { BUTTON, DESCRIPTION, ERROR, HELPER, REQUEST_LINK, PLACEHOLDER, TITLE };
