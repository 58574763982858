import constants from 'containers/transactions/views/landing/constants/transactions-landing.constants';
import { TRANSACTION_CONFIGURATION_PROPS } from '../components/transactions-table/configurations/constants/transactions-table.configuration.constants';
import { FETCH_EPPS_SERVICE_CONFIGURATION } from 'containers/financing/views/landing/services/constants/financing-landing.service.constants';
import { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION } from 'containers/transactions/views/landing/services/constants/transactions-landing.service.constants';
import { TransactionsTableOrganismPropsReturnType } from '../components/transactions-table/types/transactions-table-configurations.component.type';

const FINANCING_ORGANISM_TABLE_PROPS: TransactionsTableOrganismPropsReturnType = {
  ...TRANSACTION_CONFIGURATION_PROPS,
  showFilters: true,
  isFinancing: true,
  serviceConfiguration: { ...FETCH_EPPS_SERVICE_CONFIGURATION },
};
const TRANSACTION_ORGANISM_TABLE_PROPS: TransactionsTableOrganismPropsReturnType = {
  ...TRANSACTION_CONFIGURATION_PROPS,
  showFilters: true,
  listLinksConfigurations: constants?.TRANSACTIONS_TABLE_LINK_BAR_PROPS,
  serviceConfiguration: { ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION },
};
const DASHBOARD_ORGANISM_TABLE_PROPS: TransactionsTableOrganismPropsReturnType = {
  ...TRANSACTION_CONFIGURATION_PROPS,
  hasGroups: false,
  showFilters: false,
  innerPadding: false,
  serviceConfiguration: { ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION },
};

const CARD_STATEMENTS_ORGANISM_TABLE_PROPS: TransactionsTableOrganismPropsReturnType = {
  ...TRANSACTION_CONFIGURATION_PROPS,
  hasGroups: true,
  showFilters: false,
  innerPadding: false,
  serviceConfiguration: { ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION },
};

const ORGANISM_TABLE_TEST_ID_DEFAULT = 'transactions-table-organism-component';

export {
  CARD_STATEMENTS_ORGANISM_TABLE_PROPS,
  DASHBOARD_ORGANISM_TABLE_PROPS,
  FINANCING_ORGANISM_TABLE_PROPS,
  ORGANISM_TABLE_TEST_ID_DEFAULT,
  TRANSACTION_ORGANISM_TABLE_PROPS,
};
