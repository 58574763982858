// Vendors
import React from 'react';
// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
import { SecurityCheckModalComponent } from '../security-check-modal/security-check-modal.component';
// Constants
import { SECURITY_CHECK_PROPS } from './constants/security-check.constants';
// Styles
import { SecurityCheckComponentStyled } from './security-check.component.styled';
// Types
import { TransactionsSecurityCheckComponentType } from './types/security-check.component.type';
// Utils
import { showSecurityCheck } from './utils/security-check.utils';

const TransactionsSecurityCheckComponent = ({
  metadata,
  componentError,
  onClickSecurityCheckBtn,
  challenge,
  challengeError,
  formValuesParams,
  handleFetchCardTransactions,
  setChallenge,
  setChallengeError,
  setError,
  setShowSecurityCheckModal,
  showSecurityCheckModal,
  serviceConfiguration,
}: TransactionsSecurityCheckComponentType): React.ReactElement | null =>
  showSecurityCheck({ metadata, componentError }) ? (
    <SecurityCheckComponentStyled>
      <ErrorComponent
        {...SECURITY_CHECK_PROPS}
        buttonConfiguration={{
          ...SECURITY_CHECK_PROPS.buttonConfiguration,
          onClick: () => onClickSecurityCheckBtn(),
        }}
      />
      <SecurityCheckModalComponent
        {...{
          challenge,
          challengeError,
          handleFetchCardTransactions,
          formValuesParams,
          metadata,
          setChallenge,
          setChallengeError,
          setError,
          setShowSecurityCheckModal,
          showSecurityCheckModal,
          serviceConfiguration,
        }}
      />
    </SecurityCheckComponentStyled>
  ) : null;

export default TransactionsSecurityCheckComponent;
