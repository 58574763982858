// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Types
import { SecurityCheckUtilsType } from './types/security-check.utils.type';
// Utils
import { hasTransactionsChallengeSuccess } from 'organisms/table-organism/components/transactions-table/utils/transactions-table.utils';

const showSecurityCheck = ({ metadata, componentError }: SecurityCheckUtilsType): boolean => {
  const isNoResultsError = componentError === TransactionsFetchErrorEnumeration.NO_RESULTS;
  const isChallengeError = componentError === TransactionsFetchErrorEnumeration.CHALLENGE;
  const hasPendingRecordsWithoutSuccess =
    metadata.hasPendingRecords && !hasTransactionsChallengeSuccess();

  if (isNoResultsError) {
    return false;
  }

  return !isNoResultsError && (isChallengeError || hasPendingRecordsWithoutSuccess);
};

export { showSecurityCheck };
