// Translations
import {
  ASCENDING_DESCENDING_SEQUENCE,
  EQUAL_4_DIGITS,
} from './translations/access-key-constraints.utils.translations';
// Types
import { AccessKeyConstraintsGetterUtilsType } from './types/access-key-constraints-getter.utils.type';
import { FormatMessageType } from 'types/format-message.type';
// Utilities
import {
  checkNoAscendingDescendingSequences,
  checkNoUse4EqualsNumbers,
} from './access-key-constraints-checkers.utils';

const getAccessKeyConstraints = (
  formatMessage: FormatMessageType
): AccessKeyConstraintsGetterUtilsType[] => [
  {
    label: formatMessage({ id: ASCENDING_DESCENDING_SEQUENCE }),
    check: checkNoAscendingDescendingSequences,
  },
  {
    label: formatMessage({ id: EQUAL_4_DIGITS }),
    check: checkNoUse4EqualsNumbers,
  },
];

export { getAccessKeyConstraints };
