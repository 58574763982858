// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Types
import { TransactionsTableHeaderLinkBarListLinksComponentCCPropsType } from './types/transactions-table-header-link-bar-list-links.component.type.cc';

const TransactionsTableHeaderLinkBarListLinksComponentCC = ({
  listLinksConfigurations,
}: TransactionsTableHeaderLinkBarListLinksComponentCCPropsType): React.ReactElement | null => {
  return (
    <>
      {listLinksConfigurations &&
        [...listLinksConfigurations].reverse().map((listLinkConfiguration, index) => (
          <LinkComponent key={index} {...listLinkConfiguration}>
            <FormattedMessageComponent id={listLinkConfiguration.messageId} />
          </LinkComponent>
        ))}
    </>
  );
};

export { TransactionsTableHeaderLinkBarListLinksComponentCC };
