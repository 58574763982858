// Constants
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';

const TRANSACTION_CONFIGURATION_PROPS = {
  innerPadding: true,
  showActionBar: true,
  hasGroups: true,
  isFinancing: false,
  showFilters: false,
  listLinksConfigurations: [],
};

const TRANSACTIONS_TABLE_DEFAULT_ERROR_CONFIGURATION = {
  error: true,
};

const TRANSACTIONS_TABLE_ERROR_CONFIGURATIONS = {
  [TransactionsFetchErrorEnumeration.FETCH]: {
    assetId: 'oops-alternative',
  },
  [TransactionsFetchErrorEnumeration.NO_RESULTS]: {
    assetId: 'no-movements',
  },
};

export {
  TRANSACTION_CONFIGURATION_PROPS,
  TRANSACTIONS_TABLE_DEFAULT_ERROR_CONFIGURATION,
  TRANSACTIONS_TABLE_ERROR_CONFIGURATIONS,
};
