const DOMAIN = 'cobranded.services.userSettings.privateArea.cbChallengeModule.emailChallenge.';

export default {
  BUTTON: `${DOMAIN}sendButton`,
  DESCRIPTION: `${DOMAIN}description`,
  ERROR: `${DOMAIN}cbInput.errorDescription`,
  HELPER: `${DOMAIN}requestNewCodeDescription`,
  LINK: `${DOMAIN}requestNewCodeLink`,
  PLACEHOLDER: `${DOMAIN}inputOtpPlaceholder`,
  TITLE: `${DOMAIN}title`,
  TRY_AGAIN: `${DOMAIN}cbMessage.retryButton`,
  DESCRIPTION_ERROR: `${DOMAIN}cbMessage.description`,
};
