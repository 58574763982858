// Vendors
import { useEffect, useState } from 'react';
// Utilities
import { getFeatureFlagInstance } from '../utils/feature-flags.utils';
// Types
import type { FeatureFlagsReturnHookType } from './types/feature-flags-return.hook.type';

const FeatureFlagsHook = (): FeatureFlagsReturnHookType => {
  const featureFlags = getFeatureFlagInstance();
  const [fetching, setFetching] = useState(true);

  const handleLoadFeatureFlags = async () => {
    try {
      await featureFlags.loadFeatureFlags();
    } catch (_) {
      window.console.error('Error on loading feature flags');
    }

    setFetching(false);
  };

  useEffect(() => {
    handleLoadFeatureFlags();
  }, []);

  return { fetching };
};

export default FeatureFlagsHook;
