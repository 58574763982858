// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';
// Resolvers
import {
  fetchCardDetailsResolver,
  fetchCardPanResolver,
  fetchCardsResourcesResolver,
} from '../resolvers/cards.resolvers';
// Types
import { CardsBuilderReturnHandlersType } from './types/cards-builder-return.handlers.type';
import { CardsBuilderHandlersType } from './types/cards-builder.handlers.type';
import { CardsDetailsBuilderHandlersType } from './types/cards-details-builder.handlers.type';
import { CardsPanBuilderHandlersType } from './types/cards-pan-builder.handlers.type';
// Utilities
import {
  extractAllActionsFromCardUi,
  extractAllQuickActionsFromCardUi,
} from './utils/cards.handlers.utils';
import { isNumber } from 'utils/type.utils';

const fetchCardPanHandler = async ({
  details,
  setCurrentCard,
  setFetching,
}: CardsPanBuilderHandlersType): Promise<void> => {
  const [pan] = await fetchCardPanResolver(details?.id);

  setCurrentCard(Object.assign(details, { pan }));
  setFetching(false);
};

const fetchPrimaryCardDetailsInformation = async ({
  elements,
  setActions,
  setCurrentCard,
  setError,
  setFetching,
  setQuickActions,
}: CardsDetailsBuilderHandlersType): Promise<void> => {
  const { id, ui } = elements[0];
  const [details, error] = await fetchCardDetailsResolver(id);

  if (error) {
    setError(ErrorCodesEnumeration.DEFAULT);
  } else if (details) {
    const updateDetails = { ...details, ui };

    setActions(extractAllActionsFromCardUi(ui));
    setQuickActions(extractAllQuickActionsFromCardUi(ui));
    await fetchCardPanHandler({ details: updateDetails, setCurrentCard, setFetching });
  }
};

const setupViewEventHandler = async ({
  setActions,
  setCard,
  setCards,
  setCurrentCard,
  setFetching,
  setError,
  setErrorCard,
  setQuickActions,
}: CardsBuilderHandlersType): Promise<void> => {
  const [elements, error] = await fetchCardsResourcesResolver();

  if (isNumber(error)) {
    setErrorCard(true);
    setCard(false);
    setFetching(false);
    return;
  }

  if (error) {
    setError(ErrorCodesEnumeration.DEFAULT);
    setCard(false);
  } else if (elements) {
    setCards(elements);
    await fetchPrimaryCardDetailsInformation({
      elements,
      setCurrentCard,
      setFetching,
      setError,
      setActions,
      setQuickActions,
    });
    setCard(false);
  }
};

const CardsHandlers = (props: CardsBuilderHandlersType): CardsBuilderReturnHandlersType => ({
  handleSetupCardInformationEvent: () => setupViewEventHandler(props),
});

export default CardsHandlers;
