const DOMAIN = 'cobranded.services.uploadDocument.';

const uploadDocumentTranlations = {
  DRAG_AND_DROP: `${DOMAIN}dragAndDrop`,
  CHOOSE_FILE: `${DOMAIN}chooseFile`,
  CANCEL_UPLOAD: `${DOMAIN}cancelUpload`,
  REMOVE_UPLOADED_FILE: `${DOMAIN}removeUploadedFile`,
  UPLOAD_FAILED: `${DOMAIN}uploadFailed`,
  UPLOAD_FAILED_MESSAGE: `${DOMAIN}uploadFailedMessage`,
  RETRY_UPLOAD: `${DOMAIN}retryUpload`,
  INVALID_FORMAT: `${DOMAIN}invalidFormat`,
  INVALID_FORMAT_MESSAGE: `${DOMAIN}invalidFormatMessage`,
  OVERSIZED: `${DOMAIN}oversized`,
  OVERSIZED_MESSAGE: `${DOMAIN}oversizedMessage`,
};

const UPLOAD_DOCUMENT_TRANSLATIONS = {
  ADD_LINK: `${DOMAIN}addOthterFile`,
  BACK_OFFICE_ERROR_LINK: `${DOMAIN}chooseFile`,
  BACK_OFFICE_ERROR_MESSAGE: `${DOMAIN}uploadFailedMessage`,
  BACK_OFFICE_ERROR_TAG: 'Upload failed',
  BACK_OFFICE_ERROR_TEXT: `${DOMAIN}dragAndDrop`,
  BACK_OFFICE_OVERSIZE_FILE_MESSAGE: `${DOMAIN}oversizedMessage`,
  BACK_OFFICE_OVERSIZE_FILE_TAG: `${DOMAIN}oversizedTag`,
  BACK_OFFICE_INVALID_FORMAT_MESSAGE: `${DOMAIN}invalidFormatMessage`,
  BACK_OFFICE_INVALID_FORMAT_FILE_TAG: `${DOMAIN}invalidFormatTag`,
  REJECTED_LINK: `${DOMAIN}chooseFile`,
  REJECTED_MESSAGE: `${DOMAIN}rejectedFileError`,
  REJECTED_TAG: `${DOMAIN}rejectedFileTag`,
  REJECTED_TEXT: `${DOMAIN}dragAndDrop`,
  DEFAULT_TEXT: `${DOMAIN}dragAndDrop`,
  DEFAULT_LINK: `${DOMAIN}chooseFile`,
  DISABLED_TEXT: `${DOMAIN}disabledFileMessage`,
  LOADING_LINK: `${DOMAIN}cancelUpload`,
  SYSTEM_ERROR_LINK: `${DOMAIN}retryUpload`,
  SYSTEM_ERROR_MESSAGE: `${DOMAIN}retryUpload`,
  SYSTEM_ERROR_TAG: `${DOMAIN}uploadFailedMessage`,
  UPLOADED_LINK: `${DOMAIN}removeUploadedFile`,
};

export { uploadDocumentTranlations, UPLOAD_DOCUMENT_TRANSLATIONS };
