// Handlers
import TransactionsTableHeaderLinkBarTrackingHandlers from '../handlers/transactions-table-header-link-bar.tracking.handlers';
import TransactionsTableHeaderLinkBarHandlers from '../handlers/transactions-table-header-link-bar.handlers';
// Contexts
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsTableHeaderLinkBarBuilderReturnHandlersType } from '../handlers/types/transactions-table-header-link-bar-builder-return.handlers.type';

const TransactionsTableHeaderLinkBarHook =
  (): TransactionsTableHeaderLinkBarBuilderReturnHandlersType => {
    const { setIsShowFilterModal } = useFiltersContextConsumerHook();

    return TransactionsTableHeaderLinkBarHandlers({
      setIsShowFilterModal,
      ...AppTrackingHook(TransactionsTableHeaderLinkBarTrackingHandlers),
    });
  };

export default TransactionsTableHeaderLinkBarHook;
