// Types
import { CardTransactionType } from 'types/card-transaction.type';
import { EppCardType } from 'types/epp-card.type';
import { TransactionsTableListGroupsGroupComponentType } from '../../types/transactions-table-list-groups-group.component.type';
import { TransactionsTableListGroupsHandlersUtilsType } from './types/transactions-table-list-groups.handlers.utils.type';
import { TransactionsLandingTableListComponentType } from 'organisms/table-organism/components/transactions-table/components/list/types/transactions-landing-table-list.component.type';
import { TransactionsTableListGroupsInsertHandlersUtilsType } from './types/transactions-table-list-groups-insert.handlers.utils.type';

const insertOrCreateGroup = ({
  element,
  groups,
  id,
  isFinancing,
}: TransactionsTableListGroupsInsertHandlersUtilsType): number => {
  const findIndex = groups.findIndex(
    (group: TransactionsTableListGroupsGroupComponentType) => group.id === id
  );
  const date = isFinancing
    ? (element as EppCardType)?.eppCreationDate
    : (element as CardTransactionType)?.dateTime;

  return findIndex === -1
    ? groups.push({
        id,
        data: [element as any],
        date,
      })
    : groups[findIndex].data.push(element as any);
};

const catalogueElementOnGroup = ({
  groups,
  element,
  groupId = '',
  isFinancing,
}: TransactionsTableListGroupsHandlersUtilsType): number => {
  const elementDateMonth = new Date(element[groupId]).getMonth() + 1;
  return insertOrCreateGroup({ element, id: elementDateMonth, groups, isFinancing });
};

const sortByTransactionElementDateMonth = (
  a: TransactionsTableListGroupsGroupComponentType,
  b: TransactionsTableListGroupsGroupComponentType
): number => new Date(b.date).getTime() - new Date(a.date).getTime();

const createTransactionsLandingTableGroups =
  ({
    elements,
    groupId,
    isFinancing,
  }: Pick<TransactionsLandingTableListComponentType, 'elements'> &
    Pick<TransactionsLandingTableListComponentType['id'], 'groupId'> & { isFinancing?: boolean }) =>
  (): TransactionsTableListGroupsGroupComponentType[] => {
    const groups: TransactionsTableListGroupsGroupComponentType[] = [];
    elements.map((element: CardTransactionType | EppCardType) =>
      catalogueElementOnGroup({ element, groups, groupId, isFinancing })
    );
    return groups.sort(sortByTransactionElementDateMonth);
  };

export {
  createTransactionsLandingTableGroups,
  catalogueElementOnGroup,
  insertOrCreateGroup,
  sortByTransactionElementDateMonth,
};
