// Types
import { TransactionsTableFiltersLinkHandlersClickPropsType } from './types/transactions-table-header-filter-link-click-handler.type';
import { TransactionsTableHeaderLinkBarBuilderReturnHandlersType } from './types/transactions-table-header-link-bar-builder-return.handlers.type';
import { TransactionsTableHeaderLinkBarBuilderReturnTrackingHandlersType } from './types/transactions-table-header-link-bar-builder-return.tracking.handlers.type';
import { TransactionsTableHeaderLinkBarBuilderHandlersType } from './types/transactions-table-header-link-bar-builder.handlers.type';

const downloadTransactionsLinkClickHandler = ({
  handleDownloadTransactionsLinkClickTracking,
}: Pick<
  TransactionsTableHeaderLinkBarBuilderReturnTrackingHandlersType,
  'handleDownloadTransactionsLinkClickTracking'
>): void => {
  handleDownloadTransactionsLinkClickTracking();
};

const filterTransactionsLinkClickHandler = ({
  handleFilterTransactionsLinkClickTracking,
  setIsShowFilterModal,
}: TransactionsTableFiltersLinkHandlersClickPropsType): void => {
  handleFilterTransactionsLinkClickTracking();
  setIsShowFilterModal(true);
};

const TransactionsTableHeaderLinkBarHandlers = ({
  handleDownloadTransactionsLinkClickTracking,
  handleFilterTransactionsLinkClickTracking,
  setIsShowFilterModal,
}: TransactionsTableHeaderLinkBarBuilderHandlersType): TransactionsTableHeaderLinkBarBuilderReturnHandlersType => ({
  handleDownloadTransactionsLinkClick: () =>
    downloadTransactionsLinkClickHandler({ handleDownloadTransactionsLinkClickTracking }),
  handleFilterTransactionsLinkClick: () =>
    filterTransactionsLinkClickHandler({
      handleFilterTransactionsLinkClickTracking,
      setIsShowFilterModal,
    }),
});

export default TransactionsTableHeaderLinkBarHandlers;
